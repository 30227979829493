import { useEffect, useState } from 'react';
import {
  MagnifyingGlassIcon,
  XMarkIcon,
  FunnelIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/20/solid';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import debounce from 'lodash.debounce';
import { Dialog } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';
import TransactionVisualization from './TransactionVisualization'; // Visualization Component
import { exportToCSV, mapTransactionDataForCSV } from '../utils/exportUtils';



export default function Search() {
  const [keywords, setKeywords] = useState([]);
  const [keywordInput, setKeywordInput] = useState('');
  const [recipients, setRecipients] = useState([]);
  const [recipientInput, setRecipientInput] = useState('');
  const [minAmount, setMinAmount] = useState(0);
  const [maxAmount, setMaxAmount] = useState(10000000000000000);
  const [dateRange, setDateRange] = useState('Any time');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [recipientLocation, setRecipientLocation] = useState('');
  const [showCustomDateRange, setShowCustomDateRange] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [results, setResults] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]); // New state for filtered results
  const [totalAmount, setTotalAmount] = useState(0);
  const [highRiskCount, setHighRiskCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [sortField, setSortField] = useState('Transaction Amount');
  const [sortOrder, setSortOrder] = useState('desc');
  const [uniqueAgencies, setUniqueAgencies] = useState([]);
  const [uniqueRecipients, setUniqueRecipients] = useState([]);
  const [resultCount, setResultCount] = useState(0);
  const [selectedAgency, setSelectedAgency] = useState(''); // State for agency filter
  const [selectedRecipient, setSelectedRecipient] = useState(''); // State for recipient filter
  const [appliedKeywords, setAppliedKeywords] = useState([]);
  const [appliedRecipients, setAppliedRecipients] = useState([]);
  const [visualizeOpen, setVisualizeOpen] = useState(false); // Visualization Dialog State


  const awardTypeCodes = [
    'IDV_A', 'IDV_B', 'IDV_B_A', 'IDV_B_B', 'IDV_B_C', 'IDV_C', 'IDV_D', 'IDV_E',
    '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', 'A', 'B', 'C', 'D'
  ];

  const navigate = useNavigate();

  const handleSearch = debounce(async () => {
    setLoading(true);
    setAppliedKeywords([...keywords]);
    setAppliedRecipients([...recipients]);
    let aggregatedResults = [];
    let page = 1;
    let hasNextPage = true;

    while (hasNextPage) {
      const now = new Date();
      let rangeStart;

      switch (dateRange) {
        case 'Past month':
          rangeStart = new Date(now);
          rangeStart.setMonth(rangeStart.getMonth() - 1);
          break;
        case 'Past 6 months':
          rangeStart = new Date(now);
          rangeStart.setMonth(rangeStart.getMonth() - 6);
          break;
        case 'Past year':
          rangeStart = new Date(now);
          rangeStart.setFullYear(rangeStart.getFullYear() - 1);
          break;
        case 'Past 5 years':
          rangeStart = new Date(now);
          rangeStart.setFullYear(rangeStart.getFullYear() - 5);
          break;
        default:
          break;
      }

      const filters = {
        award_type_codes: awardTypeCodes,
        award_amounts: [{ lower_bound: minAmount, upper_bound: maxAmount }],
      };

      if (rangeStart) {
        filters.time_period = [
          {
            start_date: rangeStart.toISOString().split('T')[0],
            end_date: now.toISOString().split('T')[0],
          },
        ];
      }

      if (keywords.length > 0) filters.keywords = keywords;
      if (recipients.length > 0) filters.recipient_search_text = recipients;
      if (recipientLocation) filters.recipient_scope = recipientLocation;

      const body = JSON.stringify({
        filters,
        fields: [
          'Award ID', 'Recipient Name', 'Action Date', 'Transaction Amount',
          'Awarding Agency', 'Award Type'
        ],
        page,
        limit: 100,
        sort: sortField,
        order: sortOrder,
      });

      try {
        const response = await fetch('https://api.usaspending.gov/api/v2/search/spending_by_transaction/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body,
        });
        const data = await response.json();

        if (data.results && data.results.length > 0) {
          aggregatedResults = [...aggregatedResults, ...data.results];
          setResults(aggregatedResults);
          setFilteredResults(aggregatedResults); // Initially set filtered results to all results
          setResultCount(aggregatedResults.length);
          page = data.page_metadata.next || page + 1;
          hasNextPage = data.page_metadata.hasNext;
        } else {
          hasNextPage = false;
        }
      } catch (error) {
        console.error('Error fetching search results:', error);
        hasNextPage = false;
      }
    }

    const total = aggregatedResults.reduce((sum, result) => sum + Number(result['Transaction Amount']), 0);
    const highRisk = aggregatedResults.filter(result => Number(result['Transaction Amount']) > 250000000).length;

    // Extract unique agencies and recipients
    const agencies = [...new Set(aggregatedResults.map(result => result['Awarding Agency']))];
    const recipientsList = [...new Set(aggregatedResults.map(result => result['Recipient Name']))];

    setUniqueAgencies(agencies);
    setUniqueRecipients(recipientsList);
    setTotalAmount(total);
    setHighRiskCount(highRisk);
    setCurrentPage(1);
    setLoading(false);
    
  }, 500);

  const handleSearchClick = () => {
    setResults([]);
    setSelectedAgency('');
    setSelectedRecipient('');
    handleSearch();
  };

  // Filter results based on selectedAgency, selectedRecipient, sortField, and sortOrder
  useEffect(() => {
    let filtered = results.filter(result =>
      (selectedAgency === '' || result['Awarding Agency'] === selectedAgency) &&
      (selectedRecipient === '' || result['Recipient Name'] === selectedRecipient)
    );

    // Sort filtered results
    if (sortField && sortOrder) {
      filtered = filtered.sort((a, b) => {
        const field = sortField.includes('Amount') ? 'Transaction Amount' : 'Action Date';
        const multiplier = sortOrder === 'asc' ? 1 : -1;
        return (a[field] < b[field] ? -1 : 1) * multiplier;
      });
    }

    setFilteredResults(filtered);
    setResultCount(filtered.length);
  }, [selectedAgency, selectedRecipient, sortField, sortOrder, results]);

  const handleAddKeyword = (event) => {
    if (event.key === ',' || event.key === 'Tab' || event.key === 'Enter') {
      event.preventDefault();
      if (keywordInput.trim()) {
        setKeywords([...keywords, keywordInput.trim()]);
        setKeywordInput('');
      }
    }
  };
  const handleRemoveKeyword = (keyword) => setKeywords(keywords.filter((k) => k !== keyword));
  const handleAddRecipient = (event) => {
    if (event.key === ',' || event.key === 'Tab' || event.key === 'Enter') {
      event.preventDefault();
      if (recipientInput.trim()) {
        setRecipients([...recipients, recipientInput.trim()]);
        setRecipientInput('');
      }
    }
  };
  const handleRemoveRecipient = (recipient) => setRecipients(recipients.filter((r) => r !== recipient));

  const totalPages = Math.ceil(filteredResults.length / itemsPerPage);
  const paginatedResults = filteredResults.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  const handlePreviousPage = () => setCurrentPage(prev => Math.max(prev - 1, 1));
  const handleNextPage = () => setCurrentPage(prev => Math.min(prev + 1, totalPages));

  const handleOpenAward = (generatedInternalId) => {
    if (generatedInternalId) {
      window.open(`/award/${generatedInternalId}`, '_blank');
    }
  };

  const handleOpenVisualization = () => setVisualizeOpen(true);
  const handleCloseVisualization = () => setVisualizeOpen(false);

  const renderSkeletonLoader = () => (
    <div className="mt-4 overflow-hidden rounded-lg shadow bg-gray-800 text-white">
      <table className="min-w-full divide-y divide-gray-700">
        <thead>
          <tr>{['Award ID', 'Recipient', 'Agency', 'Amount', 'Date'].map((header, index) => (
            <th key={index} className="px-3 py-3.5 text-left text-sm font-semibold text-gray-400">{header}</th>
          ))}</tr>
        </thead>
        <tbody className="divide-y divide-gray-700">
          {[...Array(10)].map((_, index) => (
            <tr key={index} className="animate-pulse">
              <td className="px-3 py-4"><div className="h-4 bg-gray-700 rounded"></div></td>
              <td className="px-3 py-4"><div className="h-4 bg-gray-700 rounded"></div></td>
              <td className="px-3 py-4"><div className="h-4 bg-gray-700 rounded"></div></td>
              <td className="px-3 py-4"><div className="h-4 bg-gray-700 rounded"></div></td>
              <td className="px-3 py-4"><div className="h-4 bg-gray-700 rounded"></div></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const QuickInsightsPanel = () => (
    <div className="quick-insights-panel flex flex-col sm:flex-row gap-4 my-4">
      <div className="bg-gray-800 p-4 rounded shadow text-center flex-1 min-w-[150px]">
        <p className="text-sm text-gray-400">Total Amount</p>
        <p className="text-lg font-semibold text-white">
          ${totalAmount.toLocaleString()}
        </p>
      </div>
      <div className="bg-gray-800 p-4 rounded shadow text-center flex-1 min-w-[150px]">
        <p className="text-sm text-gray-400">High-Risk Transactions 250M+</p>
        <p className="text-lg font-semibold text-red-500">{highRiskCount}</p>
      </div>
    </div>
  );

  const renderCardView = () => (
    <div className="sm:hidden grid gap-4 mt-4">
      {paginatedResults.map((result, index) => (
        <div key={index} className="bg-gray-800 p-4 rounded-lg shadow">
          <p className="text-sm text-gray-400 mb-1">{result['Action Date']}</p>
          <p className="text-lg font-semibold text-blue-400">{result['Recipient Name']}</p>
          <p className="text-sm text-gray-300">{result['Awarding Agency']}</p>
          <p className="text-lg font-semibold text-white mt-2">${Number(result['Transaction Amount']).toLocaleString()}</p>
          <button onClick={() => handleOpenAward(result.generated_internal_id)}
            className="mt-3 text-sm text-indigo-300 underline">
            Explore
          </button>
        </div>
      ))}
    </div>
  );


  const handleSortChange = (e) => {
    const value = e.target.value;
  
    if (value === "Action Date (newest to oldest)") {
      setSortField("Action Date");
      setSortOrder("desc");
    } else if (value === "Action Date (oldest to newest)") {
      setSortField("Action Date");
      setSortOrder("asc");
    } else if (value === "Transaction Amount (highest to lowest)") {
      setSortField("Transaction Amount");
      setSortOrder("desc");
    } else if (value === "Transaction Amount (lowest to highest)") {
      setSortField("Transaction Amount");
      setSortOrder("asc");
    }
  };

  const handleExportToCSV = () => {
    const csvData = mapTransactionDataForCSV(filteredResults);
    exportToCSV(csvData, 'transactions.csv');
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8 text-white">
      <div className="text-center">
        <h1 className="text-2xl font-semibold">Transaction Search</h1>
        <p className="mt-2 text-sm text-gray-400">Search government transactions with filters and pagination.</p>
      </div>

      {/* Main Search Area */}
      <div className="mt-6 space-y-4">
        <div className="flex items-center bg-gray-800 rounded p-2 border border-gray-600">
          <MagnifyingGlassIcon className="h-5 w-5 text-gray-400 mr-2" />
          <input
            type="text"
            placeholder="Enter keywords and press Tab or comma"
            value={keywordInput}
            onChange={(e) => setKeywordInput(e.target.value)}
            onKeyDown={handleAddKeyword}
            className="flex-grow bg-transparent focus:outline-none text-white"
          />
          <div className="flex flex-wrap gap-2 ml-2">
            {keywords.map((keyword, index) => (
              <div key={index} className="flex items-center px-2 py-1 bg-blue-500 text-white text-xs rounded">
                {keyword}
                <button onClick={() => setKeywords(keywords.filter((k) => k !== keyword))} className="ml-1">
                  <XMarkIcon className="h-3 w-3" />
                </button>
              </div>
            ))}
          </div>
          <button onClick={() => setIsFilterModalOpen(true)} className="p-2 bg-gray-700 rounded hover:bg-gray-600 text-gray-300 ml-2">
            <FunnelIcon className="h-5 w-5" />
          </button>
        </div>

        <div className="flex space-x-2 items-center">
          <select
            value={dateRange}
            onChange={(e) => {
              setDateRange(e.target.value);
              setShowCustomDateRange(e.target.value === 'Custom range');
            }}
            className="p-2 border border-gray-600 rounded bg-gray-800 text-white w-full text-sm"
          >
            <option value="Any time">Any time</option>
            <option value="Past month">Past month</option>
            <option value="Past 6 months">Past 6 months</option>
            <option value="Past year">Past year</option>
            <option value="Past 5 years">Past 5 years</option>
            <option value="Custom range">Custom range...</option>
          </select>
          {showCustomDateRange && (
            <div className="flex space-x-2">
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                placeholderText="Start date"
                className="p-2 border border-gray-600 rounded bg-gray-800 text-white text-sm w-full"
              />
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                placeholderText="End date"
                className="p-2 border border-gray-600 rounded bg-gray-800 text-white text-sm w-full"
              />
            </div>
          )}
        </div>

        <button onClick={handleSearchClick} className="mt-4 w-full bg-blue-600 p-2 rounded text-white hover:bg-blue-700" disabled={loading}>
          {loading ? 'Loading...' : 'Search'}
        </button>
      </div>

      {/* Applied Keywords and Recipients */}
      {(appliedKeywords.length + appliedRecipients.length) > 0 ? 
        <div className="my-4">
          <p className="text-sm font-semibold text-gray-400 mb-2">Applied Filters:</p>
          <div className="flex flex-wrap gap-2">
            {appliedKeywords.map((keyword, index) => (
              <div key={index} className="flex items-center px-2 py-1 bg-blue-500 text-white text-xs rounded">
                {keyword}
              </div>
            ))}
            {appliedRecipients.map((recipient, index) => (
              <div key={index} className="flex items-center px-2 py-1 bg-green-500 text-white text-xs rounded">
                {recipient}
              </div>
            ))}
          </div>
        </div> : <div></div>}
    

      {/* Result Count Indicator */}
      {loading && <p className="mt-4 text-center text-gray-400">Results found: {resultCount}</p>}

      {/* Quick Insights Panel */}
      {results.length > 0 && <QuickInsightsPanel />}

      

      {/* Filter Options */}
      {results.length > 0 && (
        <div className="mt-4 flex flex-col sm:flex-row gap-4 items-center">
          <select
            className="p-2 border border-gray-600 rounded bg-gray-800 text-white w-full sm:w-1/3 text-sm"
            value={selectedAgency}
            onChange={(e) => setSelectedAgency(e.target.value)} // Update selectedAgency on change
          >
            <option value="">Select Awarding Agency</option>
            {uniqueAgencies.map((agency, index) => (
              <option key={index} value={agency}>{agency}</option>
            ))}
          </select>
          <select
            className="p-2 border border-gray-600 rounded bg-gray-800 text-white w-full sm:w-1/3 text-sm"
            value={selectedRecipient}
            onChange={(e) => setSelectedRecipient(e.target.value)} // Update selectedRecipient on change
          >
            <option value="">Select Recipient</option>
            {uniqueRecipients.map((recipient, index) => (
              <option key={index} value={recipient}>{recipient}</option>
            ))}
          </select>
          <select
  value={`${sortField} (${sortOrder === "desc" ? "highest to lowest" : "lowest to highest"})`}
  onChange={handleSortChange}
  className="p-2 border border-gray-600 rounded bg-gray-800 text-white w-full sm:w-1/3 text-sm"
>
  <option value="Action Date (newest to oldest)">Action Date (newest to oldest)</option>
  <option value="Action Date (oldest to newest)">Action Date (oldest to newest)</option>
  <option value="Transaction Amount (highest to lowest)">Amount (highest to lowest)</option>
  <option value="Transaction Amount (lowest to highest)">Amount (lowest to highest)</option>
</select>

{filteredResults.length > 0 && (
    <button
      onClick={handleOpenVisualization}
      className="p-2 rounded bg-green-600 text-white hover:bg-green-700 flex items-center justify-center w-full sm:w-1/3"
      aria-label="Visualize"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 24 24"
        className="w-6 h-6"
      >
        <path d="M4 12h2v7H4v-7zm4-6h2v13H8V6zm4 4h2v9h-2v-9zm4-7h2v16h-2V3zm4 5h2v11h-2V8z" />
      </svg>
    </button>
  )}

<button
  onClick={handleExportToCSV}
  className="p-2 bg-green-600 rounded text-white hover:bg-green-700 w-full sm:w-1/3"
>
  CSV
</button>

        </div>
      )}
      

      {/* Results or Skeleton Loader */}
      {loading ? (
        renderSkeletonLoader()
      ) : filteredResults.length > 0 ? (
        <>
          <div className="hidden sm:block mt-4 overflow-hidden rounded-lg shadow bg-gray-800 text-white">
            <table className="min-w-full divide-y divide-gray-700">
              <thead>
                <tr>{['Date', 'Agency', 'Recipient', 'Amount', 'Explore'].map((header, index) => (
                  <th key={index} className="px-3 py-3.5 text-left text-sm font-semibold text-gray-400">{header}</th>
                ))}</tr>
              </thead>
              <tbody className="divide-y divide-gray-700">
                {paginatedResults.map((result, index) => {
                  const amount = Number(result['Transaction Amount']);
                  const isHighRisk = amount > 250000000;
                  return (
                    <tr key={index} className="hover:bg-gray-700 transition">
                      <td className="px-3 py-4 text-sm text-gray-300">{result['Action Date']}</td>
                      <td className="px-3 py-4 text-sm text-gray-300">{result['Awarding Agency']}</td>
                      <td className="px-3 py-4 text-sm text-blue-400">{result['Recipient Name']}</td>
                      <td className="px-3 py-4 text-sm text-gray-300 flex items-center">
                        {isHighRisk && <ExclamationTriangleIcon className="h-5 w-5 text-red-500 mr-1" />}
                        ${Number(amount).toLocaleString()}
                      </td>
                      <td className="pl-3">
                        <button onClick={() => handleOpenAward(result.generated_internal_id)}
                          className="order-first flex-none rounded-full bg-indigo-400/10 px-3 py-2 text-xs font-medium text-indigo-300 ring-1 ring-inset ring-indigo-400/30 sm:order-none">
                          Explore
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {renderCardView()}
        </>
      ) : (
        !loading && <p className="mt-4 text-center text-gray-400">No results found. Try adjusting your search criteria.</p>
      )}

      {/* Pagination Controls */}
      {filteredResults.length > 0 && (
        <div className="mt-4 flex items-center justify-between">
          <button onClick={handlePreviousPage} disabled={currentPage === 1 || loading}
            className="rounded-md bg-gray-600 px-4 py-2 text-sm font-semibold text-gray-200 disabled:opacity-50">
            Previous
          </button>
          <span className="text-sm font-semibold text-gray-300">Page {currentPage} of {totalPages}</span>
          <button onClick={handleNextPage} disabled={currentPage === totalPages || loading}
            className="rounded-md bg-gray-600 px-4 py-2 text-sm font-semibold text-gray-200 disabled:opacity-50">
            Next
          </button>
        </div>
      )}

      {/* Visualization Dialog */}
      <TransactionVisualization
        isOpen={visualizeOpen}
        onClose={handleCloseVisualization}
        data={filteredResults}
      />

      {/* Filter Modal */}
      <Dialog open={isFilterModalOpen} onClose={() => setIsFilterModalOpen(false)} className="relative z-50">
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="max-w-md w-full rounded bg-gray-800 p-6 text-white">
            <Dialog.Title className="text-xl font-semibold mb-4">Additional Filters</Dialog.Title>
            <div className="space-y-4">
              <div>
                <input type="text" placeholder="Enter recipient names and press Tab or comma" value={recipientInput}
                  onChange={(e) => setRecipientInput(e.target.value)} onKeyDown={handleAddRecipient}
                  className="w-full p-2 border border-gray-600 rounded bg-gray-800 text-white text-sm" />
                <div className="flex flex-wrap gap-2 mt-2">
                  {recipients.map((recipient, index) => (
                    <div key={index} className="flex items-center px-2 py-1 bg-blue-500 text-white text-xs rounded">
                      {recipient}
                      <button onClick={() => handleRemoveRecipient(recipient)} className="ml-1">
                        <XMarkIcon className="h-3 w-3" />
                      </button>
                    </div>
                  ))}
                </div>
              </div>
              <select value={recipientLocation} onChange={(e) => setRecipientLocation(e.target.value)}
                className="w-full p-2 border border-gray-600 rounded bg-gray-800 text-white text-sm">
                <option value="">Location Scope</option>
                <option value="domestic">Domestic</option>
                <option value="foreign">Foreign</option>
              </select>
              <div className="flex space-x-2">
                <input type="number" placeholder="Min Amount" value={minAmount} onChange={(e) => setMinAmount(Number(e.target.value))}
                  className="w-full p-2 border border-gray-600 rounded bg-gray-800 text-white text-sm" />
                <input type="number" placeholder="Max Amount" value={maxAmount} onChange={(e) => setMaxAmount(Number(e.target.value))}
                  className="w-full p-2 border border-gray-600 rounded bg-gray-800 text-white text-sm" />
              </div>
            </div>
            <button onClick={() => setIsFilterModalOpen(false)} className="mt-6 w-full bg-blue-600 p-2 rounded text-white hover:bg-blue-700">
              Apply Filters
            </button>
          </Dialog.Panel>
        </div>
      </Dialog>
    </div>
  );
}
