// TransactionVisualization.js

import React from 'react';
import { Dialog } from '@headlessui/react';
import { Bar, Line } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import { XMarkIcon } from '@heroicons/react/20/solid';

// Import Chart.js components
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// Register required components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

export default function TransactionVisualization({ isOpen, onClose, data }) {
  // Aggregate data for the bar chart
  const aggregateDataByAgency = () => {
    const aggregated = {};
    data.forEach((transaction) => {
      const agency = transaction['Awarding Agency'] || 'Unknown Agency';
      const amount = Number(transaction['Transaction Amount'] || 0);
      aggregated[agency] = (aggregated[agency] || 0) + amount;
    });
    return aggregated;
  };

  const barChartData = () => {
    const aggregatedData = aggregateDataByAgency();
  
    // Convert aggregated data to an array of [key, value] pairs, then sort by value (transaction amount) in descending order
    const sortedData = Object.entries(aggregatedData).sort((a, b) => b[1] - a[1]);
  
    // Take the top 5 entries
    const top5Data = sortedData.slice(0, 5);
  
    // Extract labels (agency names) and data (transaction amounts)
    const labels = top5Data.map(([agency]) => agency);
    const data = top5Data.map(([, amount]) => amount);
  
    return {
      labels, // Top 5 agency names
      datasets: [
        {
          label: 'Top 5 Agencies by Total Transaction Amounts',
          data, // Top 5 transaction amounts
          backgroundColor: 'rgba(54, 162, 235, 0.6)',
          borderColor: 'rgba(54, 162, 235, 1)',
          borderWidth: 1,
        },
      ],
    };
  };

  // Aggregate data for the line chart (spending over time)
  const spendingOverTimeData = () => {
    const aggregated = {};
    data.forEach((transaction) => {
      const date = transaction['Action Date'] ? transaction['Action Date'].slice(0, 7) : 'Unknown Date'; // Group by month (YYYY-MM)
      const amount = Number(transaction['Transaction Amount'] || 0);
      aggregated[date] = (aggregated[date] || 0) + amount;
    });
    const sortedDates = Object.keys(aggregated).sort(); // Sort dates
    return {
      labels: sortedDates,
      datasets: [
        {
          label: 'Total Spending Over Time',
          data: sortedDates.map((date) => aggregated[date]),
          fill: false,
          backgroundColor: 'rgba(75, 192, 192, 0.4)',
          borderColor: 'rgba(75, 192, 192, 1)',
          tension: 0.1, // Smooth line
        },
      ],
    };
  };

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="relative max-w-4xl w-full max-h-[80vh] overflow-y-auto rounded bg-gray-800 p-6 text-white">
          {/* Close Button */}
          <button
            onClick={onClose}
            className="absolute top-4 right-4 p-1 rounded-full hover:bg-gray-700"
            aria-label="Close"
          >
            <XMarkIcon className="h-6 w-6 text-gray-300" />
          </button>

          <Dialog.Title className="text-xl font-semibold mb-4">Transaction Visualizations</Dialog.Title>

          {/* Bar Chart: Total Transaction Amounts by Agency */}
          <div className="h-96 mb-8">
            <h2 className="text-lg font-semibold mb-4">Transaction Amounts by Agency</h2>
            <Bar data={barChartData()} options={{ responsive: true, maintainAspectRatio: false }} />
          </div>

          {/* Line Chart: Spending Over Time */}
          <div className="h-96 mt-20 mb-10">
            <h2 className="text-lg font-semibold mb-4">Spending Over Time</h2>
            <Line data={spendingOverTimeData()} options={{ responsive: true, maintainAspectRatio: false }} />
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}

TransactionVisualization.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
};
