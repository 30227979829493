import { unparse } from 'papaparse';
import { saveAs } from 'file-saver';

/**
 * Exports data to a CSV file.
 * @param {Array} data - The data to export.
 * @param {string} filename - The name of the exported file.
 */
export const exportToCSV = (data, filename = 'export.csv') => {
  if (!data || data.length === 0) {
    alert('No data available to export.');
    return;
  }

  // Convert the data to CSV format using papaparse's `unparse` method
  const csvData = unparse(data);

  // Create a Blob and download the file
  const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
  saveAs(blob, filename);
};

/**
 * Maps transaction data into a format suitable for CSV export.
 * @param {Array} transactions - The transactions data.
 * @returns {Array} Mapped data.
 */
export const mapTransactionDataForCSV = (transactions) => {
  return transactions.map((transaction) => ({
    AwardID: transaction['generated_internal_id'] || 'N/A',
    Date: transaction['Action Date'] || 'N/A',
    AwardType: transaction['Award Type'] || 'N/A',
    Agency: transaction['Awarding Agency'] || 'N/A',
    Recipient: transaction['Recipient Name'] || 'N/A',
    Amount: transaction['Transaction Amount']
  }));
};
