import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Feed from './components/Feed';
import DetailedTable from './components/DetailedTable';
import LandingPage from './landing';
import RecipientDetails from './components/RecipientDetails';
import AwardPage from './components/AwardPage';
import TransactionSearch from './components/TransactionSearch';
import Footer from './components/Footer';
import AwardSearch from './components/AwardSearch';
import LoginPage from './components/LoginPage';
import SignupPage from './components/SignupPage';
import Profile from './components/ProfilePage';
import FlaggedAwards from './components/FlaggedAwardsPage';
import FlaggedFeed from './components/FlaggedFeed';


function App() {
  return (
    <Router>
      <div className="bg-gray-900 h-full" style={{ minHeight: '100vh' }}>
        <Header />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/Feed" element={<Feed />} />
          <Route path="/details" element={<DetailedTable />} />
          <Route path="/recipients/:uei" element={<RecipientDetails />} />
          <Route path="/award/:generated_internal_id" element={<AwardPage />} />
          <Route path="/transactionsearch" element={<TransactionSearch />} />
          <Route path="/awardsearch" element={<AwardSearch />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/flaggedawards" element={<FlaggedAwards />} />
          <Route path="/flaggedfeed" element={<FlaggedFeed />} />


        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
