import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';

export default function AwardPage() {
  const { generated_internal_id } = useParams();
  const navigate = useNavigate();
  const [awardDetails, setAwardDetails] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isFlagged, setIsFlagged] = useState(false);
  const [userId, setUserId] = useState(null);
  const [reason, setReason] = useState('');
  const [explanation, setExplanation] = useState('');
  const [showFlagModal, setShowFlagModal] = useState(false);
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
  const [showCopyModal, setShowCopyModal] = useState(false);



  // Fetch the user ID on mount
  useEffect(() => {
    const fetchUser = async () => {
      const { data: { user }, error } = await supabase.auth.getUser();
      if (error) {
        console.error('Error fetching user:', error);
      } else if (user) {
        setUserId(user.id);
      }
    };
    fetchUser();
  }, []);

  // Check if the current user has already flagged this award
  useEffect(() => {
    const checkIfFlagged = async () => {
      if (!userId) return;

      const { data, error } = await supabase
        .from('flagged_awards')
        .select('id')
        .eq('user_id', userId)
        .eq('generated_internal_id', generated_internal_id)
        .single();

      if (data) {
        setIsFlagged(true);
      } else if (error && error.code !== 'PGRST116') {
        console.error('Error checking flag status:', error);
      }
    };

    checkIfFlagged();
  }, [userId, generated_internal_id]);

  // Statistics state
  const [stats, setStats] = useState([
    { name: 'Total Obligations', value: 0, unit: '$' },
    { name: 'Number of Transactions', value: 0 },
    { name: 'Average Transaction', value: 0, unit: '$' },
  ]);

  // Fetch award details and transactions using awardId from URL
  useEffect(() => {
    const fetchAwardDetails = async () => {
      try {
        const response = await fetch(`https://api.usaspending.gov/api/v2/awards/${generated_internal_id}/`);
        if (!response.ok) throw new Error("Failed to fetch award details");

        const data = await response.json();
        setAwardDetails(data);

        // Set Total Obligations statistic
        setStats((prevStats) => [
          { ...prevStats[0], value: data.total_obligation?.toLocaleString() || "N/A" },
          ...prevStats.slice(1),
        ]);
      } catch (error) {
        console.error('Error fetching award details:', error);
        navigate("/"); 
      }
    };

    const fetchTransactions = async (page = 1) => {
      try {
        const response = await fetch('https://api.usaspending.gov/api/v2/transactions/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            award_id: generated_internal_id,
            page,
            sort: 'action_date',
            order: 'desc',
            limit: 5000,
          }),
        });
        const data = await response.json();
        setTransactions(data.results || []);
        setTotalPages(Math.ceil(data.page_metadata.total / 10));

        const totalObligations = data.results.reduce((sum, transaction) => sum + (transaction.federal_action_obligation || 0), 0);
        const totalTransactions = data.results.length;
        const averageTransaction = totalTransactions > 0 ? totalObligations / totalTransactions : 0;

        setStats([
          { name: 'Total Obligations', value: Math.round(totalObligations).toLocaleString(), unit: '$' },
          { name: 'Number of Transactions', value: totalTransactions.toLocaleString() },
          { name: 'Average Transaction', value: Math.round(averageTransaction).toLocaleString(), unit: '$' },
        ]);
      } catch (error) {
        console.error('Error fetching transactions:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAwardDetails();
    fetchTransactions(currentPage);
  }, [generated_internal_id, navigate, currentPage]);

  const toggleDescription = () => setIsDescriptionExpanded(!isDescriptionExpanded);



  const handleFlagAward = async () => {
    if (!userId) {
      console.error('User not logged in');
      return;
    }

    const obligation = awardDetails?.total_obligation || null; // Ensure correct path for total obligation
    const { recipient_name } = awardDetails?.recipient || {};
    const { name: awarding_agency } = awardDetails?.awarding_agency?.toptier_agency || {};
    const { description } = awardDetails || {};

    const { error } = await supabase
      .from('flagged_awards')
      .insert([
        {
          user_id: userId,
          generated_internal_id,
          reason,
          explanation,
          obligation,
          recipient_name: recipient_name || null,
          awarding_agency: awarding_agency || null,
          description: description || null,
          last_updated: new Date(),
        },
      ]);

    if (error) {
      console.error('Error flagging award:', error);
    } else {
      console.log('Award flagged successfully');
      setShowFlagModal(false);
      setReason('');
      setExplanation('');
      setIsFlagged(true); 
    }
  };

  const renderFlagModal = () => (
    showFlagModal && (
      <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75">
        <div className="bg-gray-800 rounded-lg p-6 space-y-6 w-full max-w-md mx-auto shadow-lg transform transition-all">
          <h2 className="text-2xl font-semibold text-white text-center">Flag Award for Cutting</h2>
          <p className="text-sm text-gray-400 text-center">
            Let us know why this award should be cut. Select a reason and provide additional details.
          </p>

          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-300">Reason</label>
              <select
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                className="mt-1 block w-full rounded-md border-0 bg-gray-700 py-2 px-3 text-white shadow-sm focus:ring-2 focus:ring-indigo-500 sm:text-sm"
              >
                <option value="">Select a reason</option>
                <option value="Unnecessary Spending">Unnecessary Spending</option>
                <option value="Outdated Project">Outdated Project</option>
                <option value="Low Impact">Low Impact</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-300">Explanation</label>
              <textarea
                value={explanation}
                onChange={(e) => setExplanation(e.target.value)}
                className="mt-1 block w-full rounded-md bg-gray-700 py-2 px-3 text-white shadow-sm focus:ring-2 focus:ring-indigo-500 sm:text-sm"
                placeholder="Provide additional details..."
                rows={4}
              />
            </div>
          </div>

          <div className="flex justify-end space-x-2">
            <button
              onClick={() => setShowFlagModal(false)}
              className="px-4 py-2 bg-gray-600 text-sm font-semibold text-gray-300 rounded hover:bg-gray-500 transition"
            >
              Cancel
            </button>
            <button
              onClick={handleFlagAward}
              className="px-4 py-2 bg-red-500 text-sm font-semibold text-white rounded hover:bg-red-400 transition"
            >
              Submit for Cutting
            </button>
          </div>
        </div>
      </div>
    )
  );

  const renderAwardSummary = () => (
    <div className="flex flex-col items-start justify-between gap-x-8 gap-y-4 bg-gray-800 px-4 py-4 sm:flex-row sm:items-center sm:px-6 lg:px-8 rounded-md">
      <div>
        <div className="flex items-center gap-x-3">
          <div className="flex-none rounded-full bg-green-400/10 p-1 text-green-400">
            <div className="h-2 w-2 rounded-full bg-current" />
          </div>
          <h1 className="flex gap-x-3 text-base/7">
            <span className="font-semibold text-white">{awardDetails.awarding_agency?.toptier_agency?.name || "N/A"}</span>
            <span className="text-gray-600">/</span>
            <span className="font-semibold text-white">{awardDetails.recipient?.recipient_name || "N/A"}</span>
          </h1>
        </div>
        <p className="mt-2 text-xs text-gray-200 leading-5">
          {isDescriptionExpanded ? awardDetails.description : `${awardDetails.description?.substring(0, 200)}...`}
          {awardDetails.description?.length > 200 && (
            <button onClick={toggleDescription} className="ml-2 text-indigo-300 hover:underline">
              {isDescriptionExpanded ? 'Read Less' : 'Read More'}
            </button>
          )}
        </p>      
      </div>
      <button
        onClick={() => !isFlagged && setShowFlagModal(true)}
        disabled={isFlagged}
        className={`order-first flex-none rounded-full px-3 py-2 text-xs font-medium ring-1 ring-inset ${
          isFlagged
            ? 'bg-red-400/10 text-red-300 ring-red-400/30 cursor-not-allowed'
            : 'bg-red-500/10 text-red-300 ring-red-500/30 hover:bg-red-500/20'
        } sm:order-none`}
      >
        {isFlagged ? 'Already Flagged for Cutting' : 'Flag for Cutting'}
      </button>
      <button
        onClick={() => setShowCopyModal(true)}
        className="ml-4 flex-none rounded-full bg-blue-500/10 px-3 py-2 text-xs font-medium text-blue-300 ring-1 ring-inset ring-blue-500/30 hover:bg-blue-500/20"
      >
        Copy to X
      </button>
    </div>
  );

  const renderStatistics = () => (
    <div className="grid grid-cols-1 bg-gray-800 sm:grid-cols-2 lg:grid-cols-4 rounded-lg">
      {stats.map((stat, statIdx) => (
        <div
          key={stat.name}
          className={`border-t border-white/5 px-4 py-6 sm:px-6 lg:px-8 ${statIdx % 2 === 1 ? 'sm:border-l' : ''} ${statIdx === 2 ? 'lg:border-l' : ''}`}
        >
          <p className="text-sm font-medium text-gray-400">{stat.name}</p>
          <p className="mt-2 flex items-baseline gap-x-2">
            <span className="text-4xl font-semibold tracking-tight text-white">{stat.value}</span>
            {stat.unit ? <span className="text-sm text-gray-400">{stat.unit}</span> : null}
          </p>
        </div>
      ))}
    </div>
  );

  const handlePageChange = (page) => setCurrentPage(page);
  const filteredTransactions = transactions;

  const renderTransactionTable = () => (
    <div className="bg-gray-800 rounded-lg px-4 py-4">
      <h3 className="text-xl font-semibold text-white mb-4">Transaction History</h3>
      <div className="overflow-x-auto">
        <table className="min-w-full whitespace-nowrap text-left">
          <thead className="border-b border-white/10 text-sm font-semibold text-gray-400">
            <tr>
              <th className="py-2 px-3">Action Date</th>
              <th className="py-2 px-3">Action Type</th>
              <th className="py-2 px-3">Description</th>
              <th className="py-2 px-3 text-right">Obligation</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-white/10 text-sm text-gray-300">
            {filteredTransactions.map((transaction) => (
              <tr key={transaction.id} className="hover:bg-gray-700/20 transition">
                <td className="py-3 px-3">{transaction.action_date}</td>
                <td className="py-3 px-3">{transaction.action_type_description || "N/A"}</td>
                <td className="py-3 px-3 truncate max-w-sm">
                  {transaction.description ? `${transaction.description.substring(0, 250)}...` : "N/A"}
                </td>
                <td className="py-3 px-3 text-right">
                  ${transaction.federal_action_obligation.toLocaleString()}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex justify-center mt-4">
          {Array.from({ length: totalPages }, (_, i) => (
            <button
              key={i}
              className={`px-3 py-1 ${currentPage === i + 1 ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'} rounded-lg`}
              onClick={() => handlePageChange(i + 1)}
            >
              {i + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );

  const handleCopyToClipboard = () => {
    const awardUrl = `https://govdawgs.com/award/${generated_internal_id}`;
    
    // Use the award's last action date if available, or a fallback date
    const actionDate = awardDetails.period_of_performance_start_date || new Date().toISOString();
    const formattedDate = new Date(actionDate).toLocaleDateString('en-US', { year: 'numeric', month: 'short' });
  
    // Plain text tweet content
    const tweetContent = `Amount: $${awardDetails.total_obligation?.toLocaleString()} (${formattedDate})\n\nRecipient: ${awardDetails.recipient?.recipient_name}\n\nPurpose: ${awardDetails.description ? awardDetails.description.slice(0, 240) + '...' : 'No description available.'}\n\n${awardUrl}`;
  
    // Use the Clipboard API with plain text
    navigator.clipboard.writeText(tweetContent)
      .then(() => {
        setShowCopyModal(false);
        alert("Copied to clipboard!");
      })
      .catch((error) => {
        console.error("Failed to copy text: ", error);
        alert("Failed to copy text to clipboard.");
      });
  };
  
  

  const renderCopyModal = () => (
    showCopyModal && (
      <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75">
        <div className="bg-gray-800 rounded-lg p-6 space-y-6 w-full max-w-md mx-auto shadow-lg transform transition-all">
          <h2 className="text-2xl font-semibold text-white text-center">Copy to X</h2>
          <p className="text-sm text-gray-400 text-center">
            Copy this content to share on X.
          </p>
          <div className="bg-gray-700 p-4 rounded text-sm text-white">
            Amount: ${awardDetails.total_obligation?.toLocaleString()} ({new Date(awardDetails.action_date).toLocaleDateString('en-US', { year: 'numeric', month: 'short' })})<br />
            Recipient: {awardDetails.recipient?.recipient_name}<br />
            Purpose: {awardDetails.description ? awardDetails.description.slice(0, 240) + '...' : 'No description available.'}
          </div>
          <div className="flex justify-end space-x-2">
            <button
              onClick={() => setShowCopyModal(false)}
              className="px-4 py-2 bg-gray-600 text-sm font-semibold text-gray-300 rounded hover:bg-gray-500 transition"
            >
              Cancel
            </button>
            <button
              onClick={handleCopyToClipboard}
              className="px-4 py-2 bg-blue-500 text-sm font-semibold text-white rounded hover:bg-blue-400 transition"
            >
              Copy to Clipboard
            </button>
          </div>
        </div>
      </div>
    )
  );


  if (loading) return <p>Loading...</p>;
  if (!awardDetails) return <p>Award details not found. Redirecting...</p>;

  return (
    <div className="container mx-auto p-4 space-y-3 h-full">
      {renderAwardSummary()}
      {renderStatistics()}
      {renderTransactionTable()}
      {renderFlagModal()}
      {renderCopyModal()}

    </div>
  );
}
